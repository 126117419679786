<template>
  <v-container>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans"> Produtos Contratados </span>
      </h1>

      <v-spacer />

      <v-chip color="surface" class="mr-2" link @click="handleOrder()" light>
        {{ pagination.order === 0 ? "Mais Recentes" : "Mais Antigo" }}

        <v-icon right>
          {{ pagination.order === 0 ? "mdi-chevron-down" : "mdi-chevron-up" }}
        </v-icon>
      </v-chip>
    </div>

    <loader-content v-if="loading" />

    <section v-else>
      <!-- analytics -->
      <v-row class="mb-2">
        <v-col v-for="(item, i) in analytics" :key="i">
          <v-card class="shadow rounded-lg">
            <v-list-item>
              <v-list-item-avatar size="32" class="opacity-primary">
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-regular">
                  <span class="work-sans">
                    {{ currencyMask(item.value) }} BRL
                  </span>
                </v-list-item-title>

                <v-list-item-subtitle class="text-caption">
                  {{ item.label }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <v-card class="shadow rounded-lg mb-6" color="backgorund">
        <v-data-table
          :headers="headers"
          :items="products"
          class="transparent"
          hide-default-footer
          disable-pagination
          disable-sort
        >
          <!-- customer -->
          <template v-slot:[`item.customer`]="{ item }">
            <router-link disabled :to="`/user/${item.userID}`">
              <v-chip color="primary" link>
                <v-icon left small>mdi-account</v-icon>

                <span>{{ item.nomeUser }}</span>
              </v-chip>
            </router-link>
          </template>

          <!-- contract -->
          <template v-slot:[`item.nomeContrato`]="{ item }">
            <router-link :to="`/products/${item.id}`">
              {{ item.nomeContrato }}
            </router-link>
          </template>

          <!-- deposited  -->
          <template v-slot:[`item.valorDeposito`]="{ item }">
            R$ {{ currencyMask(item.valorDeposito) }}
          </template>

          <!-- withdraw  -->
          <template v-slot:[`item.totalSacado`]="{ item }">
            R$ {{ currencyMask(item.totalSacado) }}
          </template>

          <!-- balance -->
          <template v-slot:[`item.saldoAtual`]="{ item }">
            R$ {{ currencyMask(item.saldoAtual) }}
          </template>
        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        @input="getHiredProducts()"
      />
    </section>
  </v-container>
</template>

<script>
import { request } from "@/services";
import { handlePagination, currencyMask } from "@/utils";

export default {
  name: "HiredProducts",

  data() {
    return {
      loading: true,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        order: 0,
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Cliente", value: "customer" },
        { text: "Produto", value: "nomeContrato" },
        { text: "Valor depositado", value: "valorDeposito" },
        { text: "Valor resgatado", value: "totalSacado" },
        { text: "Saldo atual", value: "saldoAtual" },
        { text: "Data de contratação", value: "dataContrato" },
      ],
      products: [],
    };
  },

  beforeMount() {
    this.getHiredProducts();
  },

  computed: {
    analytics() {
      return [
        {
          icon: "mdi-chevron-down",
          label: "Total depositado",
          value: this.inFlow,
        },
        {
          icon: "mdi-chevron-up",
          label: "Total resgatado",
          value: this.outFlow,
        },
        {
          icon: "mdi-currency-usd",
          label: "Total atual",
          value: this.total,
        },
      ];
    },

    inFlow() {
      let total = 0;

      this.products.map((e) => {
        total += Number(e.valorDeposito);
      });

      return total;
    },

    outFlow() {
      let total = 0;

      this.products.map((e) => {
        total += Number(e.totalSacado);
      });

      return total;
    },

    total() {
      let total = 0;

      this.products.map((e) => {
        total += Number(e.saldoAtual);
      });

      return total;
    },
  },

  methods: {
    async getHiredProducts() {
      try {
        let payload = {
          method: "listUserContratosAdmin",
          page: this.pagination.page - 1,
          order: this.pagination.order,
        };

        await request(payload).then(async (res) => {
          this.products = res.userContratos;
          this.pagination.pageCount = handlePagination(res.total);
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleOrder() {
      this.pagination.order = this.pagination.order === 1 ? 0 : 1;
      this.getHiredProducts();
    },

    currencyMask,
  },
};
</script>

<style></style>
